<template>
  <div class="calendar">
    <esmp-select disabled placeholder="День" />
    <esmp-select disabled placeholder="Месяц" />
    <esmp-select disabled placeholder="Год" />
  </div>
</template>

<script>
export default {
  name: 'CalendarView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  display: flex;
  .esmp-select + .esmp-select {
    margin-left: 10px;
  }
}
</style>
